import * as React from "react";

import { Layout } from "../components/Layout";
import { ContactForm } from "../elements/ContactForm/ContactForm";

const GotIt = () => {
  return (
    <Layout invertHeader footerFixed>
      <ContactForm activeStep="third" />
    </Layout>
  );
};

export default GotIt;
